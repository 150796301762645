import { SET_QUERY_SHOW_KEY_SPLIT } from '@constant';

export default {
  data() {
    return {
      userId: window.sessionStorage.getItem('USER_ID'),
    }
  },
  methods: {
    /**
     * @description 查询结果表头变更
     */
    choosedTableCondition(arr) {
      let params = {
        TABLE_COLUMNS: arr.map(item => item.tag).join(SET_QUERY_SHOW_KEY_SPLIT),
        TABLE_COLUMNS_UI: '',
        TABLE_NAME: this.dataTableName,
        TYPE: "SHOW",
        USER_ID: this.userId
      };
      
      this.$store.dispatch('changehabit', params)
      this.tableColumnConfigList = arr;
      
      // 操作列不能移动
      let col = this.$refs.configTable.$refs.vxeTable.getColumns()
      let columns = arr.slice(0, arr.length - 1);
      columns.push(col.pop())
      columns.map(item => {
        item.minWidth = 150;
        return item
      })
      this.$refs.configTable.$refs.vxeTable.reloadColumn(columns)
      
      this.getList();
    },
    /**
     * @description 查询条件变更
     */
    choosedCondition(arr) {
      this.$store.dispatch('changehabit', {
        TABLE_COLUMNS: arr.map(item => item.key).join(SET_QUERY_SHOW_KEY_SPLIT),
        TABLE_NAME: this.dataTableName,
        TABLE_COLUMNS_UI: '',
        TYPE: "QUERY",
        USER_ID: this.userId
      })
      this.filterFormConfig = arr;
    },
    /**
     * @description 表头拖拽完顺序后 用于保存个人个性配置
     */
    headerDroped(params) {
      this.$store.dispatch('changehabit', {
        TABLE_COLUMNS: params.map(item => (item.property || item.type)).join(SET_QUERY_SHOW_KEY_SPLIT),
        TABLE_NAME: this.dataTableName,
        TABLE_COLUMNS_UI: '',
        TYPE: "SHOW",
        USER_ID: this.userId
      })
      this.tableColumnConfigList = params;
    },
    /**
     * @description 表头拖拽完变更表头宽度后 用于保存个人个性配置
     */
    headerDragend(params) {
      const {newWidth, oldWidth, column, event} = params;
      const tableColumnConfigList = this.tableColumnConfigList;
      tableColumnConfigList.forEach(thead => {
        if (thead.value === column.property) {
          thead.width = `${newWidth}`
        }
      });
      console.log('列宽变化', JSON.parse(JSON.stringify(tableColumnConfigList)))
    },
    /**
     * @description 分页变更
    */
    handlePageChange() {
      this.getList()
    },
    /**
     * @desc 全选
    */
    selectAllEvent(params) {
      if (!params.checked) {
        this.rangeSelectionList = [];
        this.selectionList = [];
      } else {
        this.rangeSelectionList = params.records;
      }
    }
  }
}