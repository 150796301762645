
export default {
  data() {
    return {
    }
  },
  methods: {
    getInitList(){
      if(this.$refs.filterForm && this.$refs.configTable){
        this.getList()
      }
    },
  }
}